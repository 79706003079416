import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginView from '../views/loginview';
import DashboardView from '../views/CustomerView';

const LoginController = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null); // State to hold user data

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        const userData = sessionStorage.getItem('user');

        // Check if the user is already logged in
        if (token && userData) {
            setUser(JSON.parse(userData)); // Set user state if exists
            navigate('/dashboard'); // Redirect to dashboard
        }
    }, [navigate]);

    const handleLogin = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login_user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const text = await response.text();
                console.error('Error response:', text);
                throw new Error('Login failed');
            }

            const data = await response.json();
            console.log(data);
            // Store token and user data in sessionStorage
            sessionStorage.setItem('token', data.token);
            sessionStorage.setItem('userData', data.user);
            console.log('User data stored in sessionStorage:', sessionStorage);

            // Update state with user data
            setUser({
                id: data.user.id,
                email: data.user.email,
                name: data.user.name,
                businessId: data.user.businessId,
                contact: data.user.contact,
            });

            // Redirect to dashboard after successful login
            // navigate('/dashboard');
        } catch (error) {
            console.error('Error during login:', error);
            // Handle error, show notification, etc.
        }
    };

    return (
        <>
            {user ? (
                <DashboardView user={user} /> // Pass user data to DashboardView
            ) : (
                <LoginView onLogin={handleLogin} />
            )}
        </>
    );
};

export default LoginController;
