import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Swal from 'sweetalert2';
import ContactForm from './contactForm';
import Slider from 'react-slick';
import '../assets/css_pages/product.css';
import AppHeaderDropdown from '../components/header/MainHeader';
import Footer from '../components/header/footer';
import 'font-awesome/css/font-awesome.min.css';
const HomeView = () => {
    console.log(process.env.REACT_APP_BASE_URL);
    useEffect(() => {
        // Check if there's a hash in the URL
        if (window.location.hash) {
            const sectionId = window.location.hash;
            const section = document.querySelector(sectionId);
            if (section) {
                // Scroll to the section smoothly
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // Spinner state
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        setIsSubmitting(true);  // Show spinner



        try {
            // Make a POST request with the email data
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }), // Send the email in the body
            });


            // Handle response
            if (response.ok) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Subscription successful!',
                    showConfirmButton: false,
                    timer: 2000 // Automatically close after 2 seconds
                });
                setEmail(''); // Clear the email input
            } else {
                const errorResponse = await response.text(); // Get error message from backend
                let errorMessage;
                try {
                    const jsonResponse = JSON.parse(errorResponse);
                    errorMessage = jsonResponse.message; // Extract the error message
                } catch (e) {
                    errorMessage = 'An unknown error occurred'; // Fallback message if parsing fails
                }
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: `Failed to subscribe: ${errorMessage}`,
                    showConfirmButton: true
                });
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'An error occurred. Please try again later.',
                showConfirmButton: true
            });
        } finally {
            setIsSubmitting(false); // Hide spinner
        }
    };

    return (

        <>
            <AppHeaderDropdown />


            <div className="preloader">
                <div className="frame">
                    <div className="center">
                        <div className="dot-1"></div>
                        <div className="dot-2"></div>

                    </div>
                </div>
            </div>


            <section id="banner">
                <div className="backtotop" bis_skin_checked="1">
                    <a href="#banner"><i className="fa fa-arrow-up" aria-hidden="true"></i></a>
                </div>
                <div className="container zindex" id="title-container">
                    <div className="row banner-section-row">
                        <div className="col-lg-7 banner-txt">
                            <h3>Say Hello To A Seamless, <span>Eco-Friendly Solution!</span></h3>
                            <p>Introducing our innovative digital receipts, designed to revolutionize the way businesses and customers interact post-purchase. Elevate your customer experience and streamline your operations with our all-in-one digital receipts.</p>
                            <a className="no-hover" style={{ textDecoration: 'none', color: 'white' }} href="#overview">Explore Now</a>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-img">
                                <img src="images/phone_and_hand_only.png" alt="banner-img" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section id="overview">
                <div className="container">
                    <div className="row header-text text-center">
                        <div className="col-lg-12">
                            <h3>FEA<span>T</span>URE</h3>
                        </div>
                    </div>
                    <div className="row over-pa">
                        <div className="col-lg-9 col-sm-12 m-auto">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-right unique-style3">
                                        <span className="over-i">
                                            {/* <img width="30" height="30" src="https://img.icons8.com/pastel-glyph/30/FFFFFF/file.png" alt="file" /> */}
                                            {/* <img width="30" height="30" src="https://img.icons8.com/external-outline-juicy-fish/30/FFFFFF/external-proof-digital-nomad-outline-outline-juicy-fish.png" alt="external-proof-digital-nomad-outline-outline-juicy-fish" /> */}
                                            <img width="40" height="40" src="https://img.icons8.com/material-sharp/40/FFFFFF/cloud-print.png" alt="cloud-print" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>Proof of Purchase</h3>
                                        <p>Easily provide customers with verified proof of purchase, enhancing trust and
                                            simplifying returns. Our digital receipts serve as secure, timestamped records
                                            that streamline the entire shopping experience, ensuring smooth transaction.</p>
                                        <div className="over-btn">
                                            {/* <a href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-left unique-style">
                                        <span className="over-i">
                                            {/* <img width="30" height="30" src="https://img.icons8.com/external-anggara-glyph-anggara-putra/30/FFFFFF/external-sheild-ecommerce-anggara-glyph-anggara-putra.png" alt="external-sheild-ecommerce-anggara-glyph-anggara-putra" /> */}
                                            <img width="40" height="40" src="https://img.icons8.com/ios-filled/40/FFFFFF/mobile-package-tracking.png" alt="mobile-package-tracking" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>Interactive Slip</h3>
                                        <p>Engage customers with our interactive receipt slips! Packed with clickable links
                                            and embedded content, they offer a richer experience, guiding users to product
                                            information, promotions, and more, right at their fingertips instantly.</p>
                                        <div className="over-btn">
                                            {/* <a href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-right unique-style2">
                                        <span className="over-i">
                                            {/* <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/share--v1.png" alt="share--v1" /> */}
                                            <img width="40" height="40" src="https://img.icons8.com/material-outlined/40/FFFFFF/graph.png" alt="graph" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>Digital Marketing</h3>
                                        <p>Transform every receipt into a marketing opportunity! Leverage integrated tools
                                            to promote special offers, loyalty programs, and targeted campaigns that help to
                                            boost customer engagement and sales directly within every receipt.</p>
                                        <div className="over-btn">
                                            {/* <a href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-left unique-style4">
                                        <span className="over-i">
                                            {/* <img width="30" height="30" src="https://img.icons8.com/ios-filled/30/FFFFFF/star--v1.png" alt="star--v1" /> */}
                                            <img width="40" height="40" src="https://img.icons8.com/ios-filled/40/FFFFFF/crowdfunding.png" alt="crowdfunding" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>After Sales Support</h3>
                                        <p>Enhance customer satisfaction with our dedicated after-sales support feature.
                                            Provide instant access to warranties, FAQs, and contacts, ensuring that your
                                            customers feel valued and supported long after the purchase is completed.</p>
                                        <div className="over-btn">
                                            {/* <a href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





            <section id="about">
                <div className="container">
                    <div className="row header-text text-center about-header">
                        <div className="col-lg-12">
                            <h3>AB<span>O</span>UT</h3>
                        </div>
                    </div>
                    <div className="row about-pa">
                        <div className="col-lg-10 col-md-12 m-auto about-item">
                            <div className="row">
                                <div className="col-lg-4 col-sm-8 m-sm-auto col-md-5 text-center">
                                    <div className="about-img ai-one">
                                        <img src="../images/About image - reciepts.png" alt="about-img" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-7 col-md-6">
                                    <div className="about-txt">
                                        {/* <span className="separet-span">About Us</span> */}
                                        {/* <h3>#1 Mexyon Presents</h3> */}
                                        <h4 style={{ wordSpacing: '2px', marginBottom: '20px' }}>Go Green with Paper-less Invoicing.</h4>
                                        <p style={{ wordSpacing: '2px', lineHeight: '1.6' }}>We're committed to promoting sustainability through innovative paperless invoicing solutions. Our digital receipts empower businesses to streamline their invoicing processes while significantly reducing their environmental footprint.<br></br> <br></br> By going green with our user-friendly platform, you not only enhance your operational efficiency but also increase customer engagement and feedback with your business. Join us in making a positive impact, one invoice at a time!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 m-auto about-item col-md-12">
                            <div className="row">
                                <div className="col-lg-7 col-md-6">
                                    <div className="about-txt sm-reduce-pa">
                                        {/* <span className="separet-span">Instant Feedback</span> */}
                                        <div className="p-bars">
                                            <h3>Instant Feedback</h3>
                                            <p style={{ wordSpacing: '2px', lineHeight: '2.6' }}>Empower your customers to share their thoughts with our seamless instant feedback option included in every digital receipt. After their purchase, customers can easily rate their experience and provide comments, helping you gather valuable insights in real-time.<br></br> <br></br> By prioritizing customer communication, you enhance satisfaction and loyalty, turning feedback into actionable improvements that foster lasting relationships. With our digital receipt software, every interaction becomes an opportunity for growth and connection!</p>
                                            {/* <div className="col-lg-12 p-bar">
                                                <span>Popular</span>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{ width: '90%' }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-lg-11 p-bar">
                                                <span>Rating</span>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{ width: '85%' }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-lg-10 p-bar">
                                                <span>Watched</span>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{ width: '72%' }} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="row counter-main">
                                        <div className="counter-1 col-4 col-lg-4 col-sm-3 col-md-4">
                                            <div className="chart" data-percent="75"></div>
                                            <h5>4.5</h5>
                                            <span>Total User</span>
                                        </div>
                                        <div className="counter-1 col-4 col-lg-4 col-sm-3 col-md-4">
                                            <div className="chart" data-percent="65"></div>
                                            <h5>4.0</h5>
                                            <span>User Rating</span>
                                        </div>
                                        <div className="counter-1 col-4 col-lg-4 col-sm-3 col-md-4">
                                            <div className="chart" data-percent="55"></div>
                                            <h5>3.8</h5>
                                            <span>Online Games</span>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-4 text-center mob-hide sm-hide col-md-5">
                                    <div className="about-img ai-two">
                                        <img src="images/image 3 reciepts.png" alt="about-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 m-auto about-item col-md-12">
                            <div className="row">
                                <div className="col-lg-4 col-sm-8 m-sm-auto text-center col-md-5">
                                    <div className="about-img ai-three">
                                        <img src="images/image reciepts replace.png" alt="about-img" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-7 col-md-6">
                                    <div className="about-txt">
                                        {/* <span className="separet-span">Mexyon Ideas</span> */}
                                        <h3>Taxation Compliant</h3>
                                        <p style={{ wordSpacing: '1px' }} >Our digital receipt software is fully taxation compliant, ensuring that all transactions meet regulatory standards. Each receipt is meticulously formatted to include essential tax information, making it easy for businesses and customers to maintain accurate records. Simplify your accounting processes and stay audit-ready with our reliable, compliant solution!</p>
                                        {/* <p className="separate-p tab-hider">We're also developing features that leverage AI to automate follow-ups and reminders, ensuring timely payments while maintaining customer engagement. By continually refining our platform, we aim to provide you with tools that not only simplify your invoicing process but also elevate your overall business strategy. Join us as we pave the way for the future of digital invoicing!</p> */}
                                        {/* <a href="#">Watch Now</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section id="product">
                <div className="container">
                    <div className="row header-text text-center scale-header">
                        <div className="col-lg-12">
                            <h3>SC<span>A</span>LE</h3>
                        </div>
                    </div>
                    <div className="row product-pa">
                        <div className="col-lg-7 mob-mar-bottom">
                            <div className="product-item">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7 product-txt">
                                        {/* <span>Made For You</span> */}
                                        <h3>Streamlined Invoice Management</h3>
                                        <p>Simplify your invoicing process with our digital solution. Upload, store, and manage all your invoices in one place, reducing administrative overhead and allowing you to focus on growth.</p>
                                        {/* <a href="#">Browse All</a> */}
                                    </div>
                                    <div className="col-lg-4 col-md-4 mob-img-hide">
                                        <img src="images/image 4 reciepts.png" alt="product-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-4">
                            <div className="product-item product-txt">
                                {/* <span>Limited Offer</span> */}
                                <h3>Enhanced Client Engagement</h3>
                                <p className="next-p">Build stronger client relationships by giving them easy access to invoices, ratings, and reviews, fostering trust and repeat business.</p>
                                {/* <a href="#">Stream Now</a> */}
                            </div>
                        </div>
                    </div>

                </div >
            </section >




            {/* <section id="team">
                <div className="container">
                    <div className="row header-text text-center team-header">
                        <div className="col-lg-12">
                            <h3>TE<span>A</span>M</h3>
                        </div>
                    </div>
                    <div className="row team-pa">
                        <div className="col-lg-3 col-md-3 col-sm-4 m-auto team-item text-center">
                            <img src="images/t1.jpg" alt="team-member" className="img-fluid" />
                            <h3>Syed Owais Shah</h3>
                            <p>CEO Founder</p>
                            <a href="#">Profile</a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-4 m-auto team-item text-center">
                            <img src="images/t2.jpg" alt="team-member" className="img-fluid" />
                            <h3>Zoya Shah</h3>
                            <p>Creative Director</p>
                            <a href="#">Profile</a>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-4 m-auto team-item text-center">
                            <img src="images/t3.jpg" alt="team-member" className="img-fluid" />
                            <h3>Muhammaed Haseeb</h3>
                            <p>Project Manager</p>
                            <a href="#">Profile</a>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* 
            <section id="price">
                <div className="container">
                    <div className="row header-text text-center">
                        <div className="col-lg-12">
                            <h3>PRI<span>C</span>ING</h3>
                        </div>
                    </div>
                    <div className="row price-pa">
                        <div className="col-lg-3 col-sm-6">
                            <div className="price-table mob-mar-bottom">
                                <h3>Free</h3>
                                <h4><sub>$</sub>0<sub>/mon</sub></h4>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> Limited Time</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> HD quality Video</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> 1 Personal Account</p>
                                <div className="price-btn text-center">
                                    <a href="#">Sign up</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="price-table mob-mar-bottom">
                                <h3>Personal</h3>
                                <h4><sub>$</sub>25<sub>/mon</sub></h4>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> Limited Time</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> HD quality Video</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> 1 Personal Account</p>
                                <div className="price-btn text-center">
                                    <a href="#">Purchase</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mob-mar-bottom">
                            <div className="price-table gold-bg">
                                <h3 className="gold">Recommend</h3>
                                <h4><sub>$</sub>99<sub>/Year</sub></h4>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> Limited Time</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> HD quality Video</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> 1 Personal Account</p>
                                <div className="price-btn gold-bg2 text-center">
                                    <a href="#">Purchase</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="price-table">
                                <h3>Business</h3>
                                <h4><sub>$</sub>199<sub>/Year</sub></h4>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> Limited Time</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> HD quality Video</p>
                                <p><i className="fa fa-check-circle-o" aria-hidden="true"></i> 1 Personal Account</p>
                                <div className="price-btn text-center">
                                    <a href="#">Purchase</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 */}

            {/* <section id="review">
                <div className="container">
                    <div className="row header-text text-center">
                        <div className="col-lg-12">
                            <h3>REV<span>I</span>EW</h3>
                        </div>
                    </div>
                    <div className="row review-pa">
                        <div className="col-lg-12">
                            <div className="review-main">
                                <div className="col-lg-6">
                                    <div className="review-item">
                                        <div className="comment">
                                            <p>
                                                <FontAwesomeIcon icon={faQuoteLeft} aria-hidden="true" />
                                                I absolutely love the digital invoice storage! It's easy to access my invoices anytime, and I no longer have to deal with paper clutter.
                                                <FontAwesomeIcon icon={faQuoteRight} aria-hidden="true" />
                                            </p>                                        </div>
                                        <div className="user">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-4 user-img">
                                                    <img src="images/testimonial1.png" alt="Shakibul Alam" />
                                                </div>
                                                <div className="col-lg-7 col-sm-7 user-txt">
                                                    <h3>Shakibul Alam</h3>
                                                    <div className="star1">
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                    </div>
                                                    <span>5 Days ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="review-item">
                                        <div className="comment">
                                            <p>
                                                <FontAwesomeIcon icon={faQuoteLeft} aria-hidden="true" />
                                                The security features are outstanding! I feel confident knowing my financial data is safe and secure with this service.
                                                <FontAwesomeIcon icon={faQuoteRight} aria-hidden="true" />
                                            </p>
                                        </div>
                                        <div className="user">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-4 user-img">
                                                    <img src="images/testimonial3.png" alt="Jessica Ava" />
                                                </div>
                                                <div className="col-lg-7 col-sm-7 user-txt">
                                                    <h3>Jessica Ava</h3>
                                                    <div className="star2">
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                    </div>
                                                    <span>2 Months ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="review-item">
                                        <div className="comment">
                                            <p>
                                                <FontAwesomeIcon icon={faQuoteLeft} aria-hidden="true" />
                                                Sharing invoices with my clients is now a breeze! The unique links make everything so much easier.
                                                <FontAwesomeIcon icon={faQuoteRight} aria-hidden="true" />
                                            </p>
                                        </div>
                                        <div className="user">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-4 user-img">
                                                    <img src="images/testimonial2.png" alt="Saiful Alam" />
                                                </div>
                                                <div className="col-lg-7 col-sm-7 user-txt">
                                                    <h3>Saiful Alam</h3>
                                                    <div className="star3">
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <FontAwesomeIcon icon={faStar} />
                                                    </div>
                                                    <span>3 Months ago</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}




            <section id="contact">
                <div className="container">
                    <div className="row header-text text-center">
                        <div className="col-lg-12">
                            <h3>CON<span>T</span>ACT</h3>
                        </div>
                    </div>
                    <div className="row contact-pa">
                        <div className="col-lg-8 f-text2">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link zom" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                01. How do I start using your digital storage solution?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            To get started, sign up for an account on our website, choose your plan, and follow the prompts to set up your digital storage.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link collapsed zom" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                02. What are your payment options?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">
                                            We accept multiple payment methods including credit cards, PayPal, and direct bank transfers for your convenience.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link collapsed zom" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                03. How can I share my invoices with clients?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">
                                            You can easily share invoices through unique links generated for each document, making access and management seamless for your clients.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link collapsed zom" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                04. Can customers provide feedback on the services?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                        <div className="card-body">
                                            Yes, we encourage customers to leave ratings and reviews to help us improve our services and build trust within our community.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ContactForm /> {/* Include the ContactForm component here */}
                    </div>
                </div>
            </section>

            <div className="modal fade show" id="exampleModal2" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title">Subscription</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group contact-input mb-3">
                                    <input
                                        type="email"
                                        className="form-control box-bg"
                                        placeholder="Email Address"
                                        aria-label="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} // Update email state
                                        required
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="dropboxx2" disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i> Submitting...
                                        </span>
                                    ) : (
                                        'Subscribe'
                                    )}
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>


            <section id="subscribe">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                            <div className="sub-main">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7">
                                        <h3>Stay Connected With Us</h3>
                                    </div>
                                    <div className="col-lg-3 col-md-5 subscripe-btn">
                                        <a href="#" data-toggle="modal" data-target="#exampleModal2" >Subcribe Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
};



export default HomeView;


