import React, { useState, useEffect } from 'react';
import '../star.css'; // Import your CSS for styling
import Swal from 'sweetalert2';

const StarRating = ({ onStarClick, itemId, prev_rating, prev_review, primary_color, secondary_color, primary_highlight_color, secondary_highlight_color }) => {
    const [rating, setRating] = useState(0); // State to keep track of selected rating
    const [reviewText, setReviewText] = useState(''); // State to store review text
    const [showReview, setShowReview] = useState(false);
    const [showReviewSection, setShowReviewSection] = useState(false); // State to control review section visibility
    const [selectedStar, setSelectedStar] = useState(0); // State to store the selected star value


    useEffect(() => {
        // Initialize the component based on prev_rating and prev_review
        if (prev_rating && prev_rating >= 1 && prev_rating <= 5) {
            setRating(prev_rating);
            setSelectedStar(prev_rating);
            setShowReviewSection(false); // Show review section as rating is present
        }
        if (prev_review && prev_review.trim() !== '') {
            setReviewText(prev_review);
            setShowReviewSection(false); // Show review section as review is present
            setShowReview(true);
        }
    }, [prev_rating, prev_review]);



    const handleClick = (value) => {
        if (prev_rating || prev_review) {
            return;
        }
        setSelectedStar(value); // Store the selected star value in state
        setRating(value); // Update the rating state
        setShowReviewSection(true); // Show review section on star click
    };
    const starRatingStyle = {
        width: '70%',
        marginLeft: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        // padding: '2em 2em 2em 5em',
        // gap: '10px',
        borderTopRightRadius: '75px',
        borderBottomRightRadius: '75px',
        marginBottom: '20px',
        border: 'none',
        position: 'relative',
        background: primary_color,
        boxShadow: '0 1px 1px hsla(0deg 0% 0%/0.075), 0 2px 2px hsla(0deg 0% 0%/0.075), 0 4px 4px hsla(0deg 0% 0%/0.075), 0 8px 8px hsla(0deg 0% 0%/0.075), 0 16px 16px hsla(0deg 0% 0%/0.075)',
    };
    const ratingValueStyle = {
        color: secondary_highlight_color,
        position: 'absolute',
        top: '-7px',
        left: '-61px',
        borderRadius: '50%',
        height: '79px',
        width: '79px',
        background: secondary_color,
        boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 1px, rgba(0, 0, 0, 0.075) 0px 2px 2px, rgba(0, 0, 0, 0.075) 0px 4px 4px, rgba(0, 0, 0, 0.075) 0px 8px 8px, rgba(0, 0, 0, 0.075) 0px 16px 16px, #495057 0px 0px 0px inset, #495057 0px 0px 0px',
        textAlign: 'center'
    };
    const buttonStyle = {
        backgroundColor: primary_color,
        // borderRadius: 'var(--button-radius)',    
        // boxShadow: 'var(--box-shadow)',         
        color: primary_highlight_color,
        // border: 'none',                        
        // padding: '10px 20px',                   
        // fontSize: '16px',                       
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };



    const svgStyle = {
        transition: 'transform 0.4s ease-in-out',
        opacity: '0.5',
    };

    const handleReviewChange = (event) => {
        setReviewText(event.target.value); // Update review text state
    };

    const handleSubmitReview = () => {
        // Implement AJAX request to submit review to your API
        console.log('Submitting review for item ID:', itemId);
        console.log('Rating:', prev_rating);
        console.log('Review Text:', prev_review);


        // Example AJAX call
        fetch(`${process.env.REACT_APP_BASE_URL}/review`, {
            method: 'POST',
            body: JSON.stringify({ itemId, rating, review: reviewText }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                // console.log('Review submitted successfully:', data);
                // Clear the review text area
                // setReviewText('');
                // Optionally, show a success message using Swal (SweetAlert)
                Swal.fire({
                    icon: 'success',
                    title: 'Review submitted successfully!',
                    text: 'Thank you for your review.',
                    timer: 2000, // Automatically close after 2 seconds
                    timerProgressBar: true,
                    showConfirmButton: false
                });
                setShowReviewSection(false);
                // setShowReviewSection(false);
            })
            .catch(error => {
                console.error('Error submitting review:', error);
                // Handle error state or display error message
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error submitting review. Please try again later.',
                    showConfirmButton: true
                });
            });
    };

    // const starRatingStyle = {
    //     width: showReviewSection ? '100%' : 'auto',
    //     textAlign: 'center'
    // };
    const starcol = {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '-5px',
        marginLeft: '6px'
    }

    return (
        <div className="container">
            <div className="row star-rating" style={starRatingStyle}>
                <div className="col-12" style={starcol} >
                    {[1, 2, 3, 4, 5].map((starValue) => (
                        <span
                            key={starValue}
                            className={starValue <= selectedStar ? "star filled" : "star"} // Dynamically set class based on selectedStar
                            onClick={() => handleClick(starValue)}
                        >
                            &#9733;
                        </span>
                    ))}
                </div>
                <fieldset className="rating-container">
                    <div className="rating-value" style={ratingValueStyle}>  {selectedStar}</div>
                </fieldset>
            </div>

            {showReviewSection && (
                <div className='row mt-3'>
                    <div className="review-section col-12">
                        <textarea
                            rows="5"
                            placeholder="Write your suggestion here"
                            className="review-textarea form-control"
                            value={reviewText}
                            onChange={handleReviewChange}
                            style={{ color: '#666', placeholderTextColor: '#D3D3D3' }}
                        />
                    </div>
                    <div className="col-12" style={{ marginTop: '15px' }}>
                        <button
                            onClick={handleSubmitReview}
                            className="btn submit-review-button w-100"
                            style={buttonStyle}  // Apply the button styles constant here
                        >
                            Submit
                        </button>
                    </div>
                </div>
            )
            }


            {showReview && (
                <div className='row mt-3'>
                    <div className="review-section col-12">
                        <p style={{
                            paddingTop: '20px',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            fontFamily: 'serif',
                            color: '#666'
                        }}>
                            “{reviewText}“
                        </p>
                    </div>
                </div>
            )}



        </div >
    );
};

export default StarRating;
