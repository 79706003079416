import React, { useEffect } from 'react';

const LogoutController = () => {
    useEffect(() => {
        console.log('Logging out user...');

        // Get the session token from localStorage
        const token = sessionStorage.getItem('token'); // Ensure the correct key is used

        // Call the backend logout API
        const logoutUser = async () => {
            try {
                const token = sessionStorage.getItem('token'); // Ensure the correct key is used

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/logout_user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ sessionToken: token }), // Send token in the request body
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Logout failed:', errorData);
                } else {
                    console.log('User logged out successfully');
                }
            } catch (error) {
                console.error('Error during logout API call:', error);
            }

            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userData');
            localStorage.removeItem('session_token'); // Clear token from localStorage if needed
            window.location.replace('/');
        };


        logoutUser();  // Execute the logout function

    }, []); // Empty dependency array ensures this runs only once when the component is mounted

    return null; // This component doesn't need to render anything
};

export default LogoutController;
