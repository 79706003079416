// src/controllers/AboutController.js
import React from 'react';
import AboutView from '../views/aboutview';

const AboutController = () => {
    const data = {
        title: 'About',
        content: 'Welcome to the About page!'
    };

    return <AboutView data = { data }
    />;
};

export default AboutController;