import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv'; // For CSV export
import jsPDF from 'jspdf'; // For PDF export
import autoTable from 'jspdf-autotable';

const DataTableComponent = ({ invoices }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Add print styles
        const style = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';
        style.innerHTML = `
            @media print {
               
                .print-content, .print-content * {
                    visibility: visible;
                }
                .print-content {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                }
                .print-hidden {
                    display: none !important;
                }
                .rdt_Pagination{
                    display:none;
                }
            }
        `;
        document.head.appendChild(style);
    }, []);

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Invoice No',
            selector: (row) => (
                <a style={{ color: 'black' }} href={`/${row.unique_code}`}>
                    {row.invoice_number}
                </a>
            ),
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: (row) => row.customer_name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => new Date(row.invoice_date).toLocaleDateString(),
            sortable: true,
        },
        {
            name: 'Cell',
            selector: (row) => row.customer_cell,
            sortable: true,
        },
        {
            name: 'Discount',
            selector: (row) => row.discount,
            sortable: true,
        },
        {
            name: 'Paid Amount',
            selector: (row) => `${row.paid_amount} (${row.payment_mode})`,
            sortable: true,
        },
        {
            name: 'Rating',
            selector: (row) => {
                const stars = Array.from({ length: 5 }, (_, index) => index < row.rating);
                return (
                    <span className="styled-tooltip">
                        {stars.map((filled, index) => (
                            <span key={index} className={`star ${filled ? 'filled' : ''}`} title={row.review}>
                                ★
                            </span>
                        ))}
                        <span className="tooltiptext">{row.review}</span>
                    </span>
                );
            },
            sortable: true,
        },
    ];

    // Handle PDF Export
    const exportPDF = () => {
        const doc = new jsPDF();
        doc.text('Invoices', 14, 16);

        // Prepare the data for PDF
        const pdfData = filteredInvoices.map((invoice, index) => [
            index + 1,
            invoice.invoice_number,
            invoice.customer_name,
            new Date(invoice.invoice_date).toLocaleDateString(),
            invoice.customer_cell,
            invoice.customer_cnic,
            invoice.cashier,
            invoice.discount,
            `${invoice.paid_amount} (${invoice.payment_mode})`,
            invoice.rating,
            invoice.ims_code
        ]);

        autoTable(doc, {
            head: [['S.No', 'Invoice No', 'Customer Name', 'Date', 'Cell', 'CNIC', 'Cashier', 'Discount', 'Paid Amount', 'Rating', 'IMS Code']],
            body: pdfData,
        });

        doc.save('invoices.pdf');
    };

    // Filter invoices based on search term
    const filteredInvoices = invoices.filter(invoice => {
        return (
            invoice.invoice_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            invoice.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div className="content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <div>
                    {/* <button className="btn btn-primary" onClick={exportPDF} style={{ margin: '2px' }}>
                        PDF
                    </button> */}
                    <CSVLink
                        data={filteredInvoices.map(invoice => ({
                            'S.No': filteredInvoices.indexOf(invoice) + 1,
                            'Invoice No': invoice.invoice_number,
                            'Customer Name': invoice.customer_name,
                            'Date': new Date(invoice.invoice_date).toLocaleDateString(),
                            'Cell': invoice.customer_cell,
                            'CNIC': invoice.customer_cnic,
                            'Cashier': invoice.cashier,
                            'Discount': invoice.discount,
                            'Paid Amount': `${invoice.paid_amount} (${invoice.payment_mode})`,
                            'Rating': invoice.rating,
                            'IMS Code': invoice.ims_code,
                        }))}
                        filename={"invoices.csv"}
                        className="btn btn-primary print-hidden"
                        style={{ margin: '2px' }}
                    >
                        CSV
                    </CSVLink>
                    <button className="btn btn-primary print-hidden" onClick={() => window.print()} style={{ margin: '2px' }}>
                        Print
                    </button>
                </div>
                <input
                    type="text"
                    className='form-control print-hidden'
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: '200px' }}
                />
            </div>
            <DataTable
                columns={columns}
                data={filteredInvoices}
                striped
                responsive
                noDataComponent="No invoices found."
                id="invoice-table"
                className='print-content'
                style={{ width: '100%' }}
                pagination

            />
        </div>
    );
};

export default DataTableComponent;
