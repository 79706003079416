import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CustomerView from '../views/CustomerView';

const DashboardController = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    const [businessData, setBusinessData] = useState(null);
    const [reviewData, setReviewData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const userData = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : null;
    const userId = userData ? userData.id : null;
    const businessId = userData ? userData.businessId : null;

    useEffect(() => {
        const fetchBusinessData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-business-data`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ userId, businessId }),
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Token expired');
                    }
                }

                const data = await response.json();
                if (data.success) {
                    // console.log('Business data fetched:', data);
                    setBusinessData(data.business);
                    setReviewData(data.reviews);
                } else {
                    throw new Error(data.message || 'Failed to fetch business data');
                }
            } catch (error) {
                handleError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (token) {
            fetchBusinessData();
        } else {
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array ensures this runs only once on mount

    const handleError = (message) => {
        if (message === "Token expired") {
            Swal.fire({
                icon: 'error',
                title: 'Session Expired',
                text: 'Please log out and log in again.',
            }).then(() => {
                sessionStorage.clear();
                navigate('/');
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: message || 'Something went wrong!',
            });
        }
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return <CustomerView businessData={businessData} reviewsData={reviewData} />;
};

export default DashboardController;
