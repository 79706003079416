import React, { useState } from 'react';
import Swal from 'sweetalert2';

const ContactForm = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Set submitting to true

        try {
            // Send the form data to your backend
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, email, message }),
            });

            if (response.ok) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Your message has been sent!',
                    icon: 'success',
                });
                // Clear form fields
                setUsername('');
                setEmail('');
                setMessage('');
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'There was an error sending your message.',
                    icon: 'error',
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'There was an error sending your message.',
                icon: 'error',
            });
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };

    return (
        <div className="col-lg-4 mobile-space">
            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control box-bg"
                        id="username"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input
                        type="email"
                        className="form-control box-bg"
                        id="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="message" className="form-label">Ask a Question</label>
                    <textarea
                        className="form-control box-bg"
                        id="message"
                        placeholder="Ask a Question..."
                        rows="3"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                </div>
                <div className="con-btn-main">
                    <button type="submit" className="con-btn" disabled={isSubmitting}>
                        {isSubmitting ? (
                            <span>
                                <i className="fa fa-spinner fa-spin"></i> Submitting...
                            </span>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
