import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import Swal from 'sweetalert2';
import $ from 'jquery';
import { faDisplay } from '@fortawesome/free-solid-svg-icons';




const ReloadableLink = ({ to, className, children }) => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleClick = (event) => {
    event.preventDefault();
    navigate(to);
    window.location.reload();
  };

  return (
    <a href={to} className={className} onClick={handleClick}>
      {children}
    </a>
  );
};

const AppHeaderDropdown = ({ handleSectionChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const sessionToken = sessionStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('login');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // Mobile view state
  const [showSubMenu, setShowSubMenu] = useState(false); // Submenu state
  const [activeSection, setActiveSection] = useState(sessionStorage.getItem('activeSection') || 'dashboard'); // Active section state
  const [menuVisible, setMenuVisible] = useState(true);  // Track navbar visibility
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    const currentPath = location.pathname;
    const currentHash = location.hash;

    if (currentHash) {
      // For sections with hash
      if (currentHash === '#about') {
        setActiveLink('about');
      } else if (currentHash === '#product') {
        setActiveLink('scale');
      } else if (currentHash === '#contact') {
        setActiveLink('contact');
      }
    } else if (currentPath.includes('/customer_view')) {
      // For customer_view sections
      const params = new URLSearchParams(location.search);
      const section = params.get('section');
      if (section) {
        // console.log(section);
        setActiveLink(section);
      } else {
        setActiveLink('dashboard');
      }
    } else {
      setActiveLink('/'); // Default to home if no hash or section
    }
  }, [location]); // Triggered when the location changes

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    // Show submenu if on customer_view page and in mobile view
    // if (window.location.pathname.includes("customer_view") && sessionToken) {
    //   setShowSubMenu(true);
    //   setMenuVisible(false); // Hide main nav when customer view is active
    // } else {
    //   setShowSubMenu(false);
    //   setMenuVisible(true); // Show main nav
    // }

    return () => window.removeEventListener('resize', handleResize);
  }, [sessionToken]);

  const toggleSubMenu = () => {
    setShowSubMenu(true);
    setMenuVisible(false); // Hide main nav when submenu is active
  };
  const handleBackButtonClick = () => {
    setMenuVisible(true); // Show main nav
    setShowSubMenu(false); // Hide submenu
  };

  const handleSubMenuClick = (section) => {
    if (typeof handleSectionChange === 'function') {
      handleSectionChange(section); // Call the section change function if it's defined
    }
    setMenuVisible(true);
    navigate(`/customer_view?section=${section}`);
    setMenuVisible(false);
  };
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: ''
  });

  const [registerFormData, setRegisterFormData] = useState({
    name: '',
    email: '',
    password: '',
    contactNumber: '',
    businessName: ''
  });

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    // console.log('close');
    $('#exampleModal').hide();
    setShowModal(false);

    // Reset specific login fields
    setLoginFormData({
      email: '',
      password: ''
    });
    setRegisterFormData({
      name: '',
      email: '',
      password: '',
      contactNumber: '',
      businessName: ''
    });
  };

  const handleLogout = async () => {
    // Get the session token from localStorage
    const token = localStorage.getItem('token'); // Ensure the correct key is used

    if (!token) {
      console.error('No session token found');
      return;
    }

    try {
      // Call the backend logout API
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/logout_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  // Send token in Authorization header
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Logout failed:', errorData);
        return;
      }

      // Clear session storage and localStorage on the client side
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userData');
      localStorage.removeItem('token');  // Clear token from localStorage

      // Redirect to the login page
      window.location.href = "/";
    } catch (error) {
      console.error('Error during logout API call:', error);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <div className="container">
          <ReloadableLink to="/" className="nav-link navbar-brand">
            <img style={{ marginLeft: '15px' }} src="images/mexyon_receipt_logo.png" alt="Mexyon" />
          </ReloadableLink>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img width="30" height="30" src="https://img.icons8.com/sf-black/64/81a683/list.png" alt="list" />
          </button>
          <div className='collapse navbar-collapse menu-main' id="navbarSupportedContent">
            <ul className={`navbar-nav ml-auto menu-item ${menuVisible ? '' : 'd-none'}`} id="navbarSupportedContent">
              <li className="nav-item">
                <ReloadableLink to="/" className={`nav-link ${activeLink === '/' ? 'active' : ''}`}>Home</ReloadableLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#about">About Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#product">Scale</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#contact">Contact</a>
              </li>
              {sessionToken && isMobile && (
                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    onClick={toggleSubMenu}
                  >
                    Dashboard
                  </a>
                  <i onClick={toggleSubMenu} className="fa fa-chevron-down"></i>
                </li>
              )}
              {sessionToken && !isMobile && (
                <li className="nav-item">
                  <a
                    href="/customer_view"
                    className={`nav-link ${activeLink === 'dashboard' ? 'active' : ''}`}
                  >
                    Dashboard
                  </a>
                </li>
              )}
              {!sessionToken && (
                <li className="nav-item">
                  <a
                    id="loginLink"
                    href="#"
                    className="nav-link bor"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={() => setActiveTab('login')}
                    style={{ textDecoration: 'none' }}
                  >
                    Login
                  </a>
                </li>

              )}
            </ul>
            {isMobile && showSubMenu && (
              <ul className='copy-nav'>
                {showSubMenu && (
                  <i onClick={handleBackButtonClick} className="fa fa-chevron-up"></i>
                )}
                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    // <a href="#" className="nav-link" onClick={() => handleSubMenuClick('dashboard')}>Dashboard</a>
                    <a href='#' onClick={() => handleSubMenuClick('dashboard')} className={`nav-link ${activeLink === 'dashboard' ? 'active' : ''}`}>Dashboard</a>
                  ) : (
                    <a href={`/customer_view?section=dashboard`} className={`nav-link ${activeLink === 'dashboard' ? 'active' : ''}`}>Dashboard</a>
                  )}
                </li>
                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'salesInvoices' ? 'active' : ''}`} onClick={() => handleSubMenuClick('salesInvoices')}>Sale Invoices</a>
                  ) : (
                    <a href={`/customer_view?section=salesInvoices`} className="nav-link">Sales Invoices</a>
                  )}
                </li>
                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'businessSettings' ? 'active' : ''}`} onClick={() => handleSubMenuClick('businessSettings')}>Business Settings</a>
                  ) : (
                    <a href={`/customer_view?section=businessSettings`} className="nav-link">Business Settings</a>
                  )}
                  {/* <a href={`/customer_view?section=businessSettings`} className="nav-link">Business Settings</a> */}
                </li>
                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'Socials' ? 'active' : ''}`} onClick={() => handleSubMenuClick('Socials')}>Socials</a>
                  ) : (
                    <a href={`/customer_view?section=Socials`} className="nav-link">Socials</a>
                  )}
                  {/* <a href={`/customer_view?section=socials`} className="nav-link">Socials</a> */}
                </li>
                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'ads' ? 'active' : ''}`} onClick={() => handleSubMenuClick('ads')}>Ads Settings</a>
                  ) : (
                    <a href={`/customer_view?section=ads`} className="nav-link">Ads Settings</a>
                  )}

                  {/* <a href={`/customer_view?section=ads`} className="nav-link">Ads Settings</a> */}
                </li>
                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'branchesListing' ? 'active' : ''}`} onClick={() => handleSubMenuClick('branchesListing')}>Branche Settings</a>
                  ) : (
                    <a href={`/customer_view?section=branchesListing`} className="nav-link">Branche Settings</a>
                  )}
                </li>
                {/* <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'smslogs' ? 'active' : ''}`} onClick={() => handleSubMenuClick('smslogs')}>Sms Logs</a>
                  ) : (
                    <a href={`/customer_view?section=smslogs`} className="nav-link">Sms Logs</a>
                  )}
                </li> */}
                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'requireDataSection' ? 'active' : ''}`} onClick={() => handleSubMenuClick('requireDataSection')}>Api Integration</a>
                  ) : (
                    <a href={`/customer_view?section=requireDataSection`} className="nav-link">Api Integration</a>
                  )}
                  {/* <a href={`/customer_view?section=editProfile`} className="nav-link">Edit Profile</a> */}
                </li>

                <li className="nav-item">
                  {window.location.pathname.includes("customer_view") && sessionToken ? (
                    <a href="#" className={`nav-link ${activeLink === 'editProfile' ? 'active' : ''}`} onClick={() => handleSubMenuClick('editProfile')}>Edit Profile</a>
                  ) : (
                    <a href={`/customer_view?section=editProfile`} className="nav-link">Edit Profile</a>
                  )}
                  {/* <a href={`/customer_view?section=editProfile`} className="nav-link">Edit Profile</a> */}
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link" href="#" onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/logout"; // or use navigate('/logout') if using `useNavigate()`
                  }}>Logout</a>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>



      <Modal
        showModal={showModal}
        closeModal={closeModal}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        navigate={navigate}
        loginFormData={loginFormData}
        setLoginFormData={setLoginFormData}
        registerFormData={registerFormData}
        setRegisterFormData={setRegisterFormData}
      />
    </>
  );
};
const Modal = ({ showModal, closeModal, activeTab, setActiveTab, navigate, loginFormData, setLoginFormData, registerFormData, setRegisterFormData }) => {
  const handleInputChange = (e) => {
    const { id, value } = e.target; // Destructure the id and value
    setRegisterFormData((prevState) => ({
      ...prevState,
      [id]: value, // Update the specific field based on input ID
    }));
  };

  const handleLoginInputChange = (e) => {
    const { id, value } = e.target;
    setLoginFormData((prevState) => ({
      ...prevState,
      [id === "loginEmail" ? "email" : "password"]: value, // Update the appropriate field
    }));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: loginFormData.email,
          password: loginFormData.password
        })
      });

      if (!response.ok) {
        const text = await response.text();
        console.error('Error response:', text);
        await Swal.fire({
          position: "center",
          icon: "error",
          title: "Invalid email or password",
          showConfirmButton: true
        });
        // Navigate to '/' after Swal is closed
        // navigate('/');
        // window.location.reload();
        return;
      }
      const data = await response.json();
      console.log(data.token);
      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('userData', JSON.stringify({
        id: data.user.id,
        email: data.user.email,
        name: data.user.name,
        businessId: data.user.businessId,
        contact: data.user.contact
      }));
      closeModal();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Login Success",
        showConfirmButton: false,
        timer: 1000
      });
    } catch (error) {
      console.error('Error:', error);
    }
    navigate('/customer_view');
    window.location.reload();
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/add_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(registerFormData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        Swal.fire({
          position: "center",
          icon: "error",
          title: errorData.msg.charAt(0).toUpperCase() + errorData.msg.slice(1),
          showConfirmButton: true
        });
        throw new Error('Registration failed');
      }

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Registration successful! Logging you in...",
        showConfirmButton: false,
        timer: 1500
      });

      const loginResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/login_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: registerFormData.email,
          password: registerFormData.password
        })
      });

      if (!loginResponse.ok) {
        const text = await loginResponse.text();
        console.error('Login failed after registration:', text);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Login failed. Please try manually.",
          showConfirmButton: true
        });
        throw new Error('Login failed after registration');
      }

      const loginData = await loginResponse.json();
      sessionStorage.setItem('token', loginData.token);
      sessionStorage.setItem('userData', JSON.stringify({
        id: loginData.user.id,
        email: loginData.user.email,
        name: loginData.user.name,
        businessId: loginData.user.businessId,
        contact: loginData.user.contact
      }));
      closeModal();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Login Success",
        showConfirmButton: false,
        timer: 1500
      });
      navigate('/dashboard');
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className={`modal fade ${showModal ? 'show d-block' : ''}`} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{activeTab === 'login' ? 'Login' : 'Register'}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setActiveTab('login')}>
              <span aria-hidden="true">×</span>
            </button>
          </div>


          <div className="modal-body">
            <div className="tab-content mt-3">
              <div className={`tab-pane fade ${activeTab === 'login' ? 'show active' : ''}`} id="login" role="tabpanel">
                <form id='login_form' onSubmit={handleLoginSubmit}>
                  <div className="input-group custom-form-input">
                    <div className="input-group-prepend">
                      <span className="input-group-text faq-icon"><i aria-hidden="true"><img width="25" height="25" src="https://img.icons8.com/ios-glyphs/25/81a683/user--v1.png" /></i></span>
                    </div>
                    <input
                      type="text"
                      className="form-control box-bg contact-input"
                      id="loginEmail"
                      placeholder="Enter Username Or Email"
                      value={loginFormData.email}
                      onChange={handleLoginInputChange} // Update here
                    />
                  </div>
                  <div className="input-group custom-form-input">
                    <div className="input-group-prepend">
                      <span className="input-group-text faq-icon"><i aria-hidden="true"><img width="25" height="25" src="https://img.icons8.com/ios-glyphs/25/81a683/lock.png" /></i></span>
                    </div>
                    <input
                      type="password"
                      id="loginPassword"
                      className="form-control box-bg contact-input"
                      placeholder="Enter your password"
                      value={loginFormData.password}
                      onChange={handleLoginInputChange} // Update her
                    />
                  </div>
                </form>
              </div>

              <div className={`tab-pane fade ${activeTab === 'register' ? 'show active' : ''}`} id="register" role="tabpanel">
                <form id='signup_form' onSubmit={handleRegisterSubmit}>
                  <div className="input-group custom-form-input mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text faq-icon"><i aria-hidden="true"><img width="25" height="25" src="https://img.icons8.com/ios-glyphs/25/81a683/user--v1.png" /></i></span>
                    </div>
                    <input
                      type="text"
                      id="name"
                      className="form-control box-bg contact-input"
                      placeholder="Enter your name"
                      value={registerFormData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group custom-form-input mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text faq-icon"><i aria-hidden="true"><img width="25" height="25" src="https://img.icons8.com/ios-glyphs/25/81a683/email.png" /></i></span>
                    </div>
                    <input
                      type="email"
                      id="email"
                      className="form-control box-bg contact-input"
                      placeholder="Enter your email"
                      value={registerFormData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group custom-form-input mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text faq-icon"><i aria-hidden="true"><img width="25" height="25" src="https://img.icons8.com/ios-glyphs/25/81a683/lock.png" /></i></span>
                    </div>
                    <input
                      type="password"
                      id="password"
                      className="form-control box-bg contact-input"
                      placeholder="Enter your password"
                      value={registerFormData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group custom-form-input mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text faq-icon"><i aria-hidden="true"><img width="25" height="25" src="https://img.icons8.com/ios-glyphs/25/81a683/phone.png" /></i></span>
                    </div>
                    <input
                      type="number"
                      id="contactNumber"
                      className="form-control box-bg contact-input"
                      placeholder="Enter your contact number"
                      value={registerFormData.contactNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="input-group custom-form-input mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text faq-icon"><i aria-hidden="true"><img width="25" height="25" src="https://img.icons8.com/ios-glyphs/25/81a683/business.png" /></i></span>
                    </div>
                    <input
                      type="text"
                      id="businessName"
                      className="form-control box-bg contact-input"
                      placeholder="Enter your business name"
                      value={registerFormData.businessName}
                      onChange={handleInputChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal-footer d-flex justify-content-between">
            <div>
              {activeTab === 'login' ? (
                <p style={{ color: 'white' }}>Don't have an account? <span onClick={() => setActiveTab('register')} style={{ cursor: 'pointer', color: '#81a683' }}>Register here</span></p>
              ) : (
                <p style={{ color: 'white' }}>Already have an account? <span onClick={() => setActiveTab('login')} style={{ cursor: 'pointer', color: '#81a683' }}>Login here</span></p>
              )}
            </div>
            <div>
              <button type="button" className="btn dropboxx2" style={{ marginRight: '10px' }} onClick={() => setActiveTab('login')} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">Close</span>
              </button>
              {activeTab === 'login' ? (
                <button type="submit" className="btn dropboxx2" onClick={handleLoginSubmit}>Login</button>
              ) : (
                <button type="submit" className="btn dropboxx2" onClick={handleRegisterSubmit}>Register</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeaderDropdown;
