// src/views/AboutView.js
import React from 'react';

const AboutView = ({ data }) => {
    return ( <
        div >
        <
        h1 > { data.title } < /h1> <
        p > { data.content } < /p> </div >
    );
};

export default AboutView;