import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HomeView from '../views/receiptview';

const HomeController = () => {
    const [data, setData] = useState(null);
    const [bannerTop, setbannerTop] = useState(null);
    const [bannerBottom, setbannerBottom] = useState(null);
    const [taxOrganization, setTaxOrganization] = useState([]);
    const [error, setError] = useState(null);
    const { code } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!code || code === '') {
                    console.error('Code parameter is missing');
                    return;
                }


                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/get-receipt/?code=${code}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const jsonData = await response.json();
                // console.log('API Response:', jsonData); // Log the API response
                setData(jsonData.data);
                setbannerTop(jsonData.bannerTop);
                setbannerBottom(jsonData.bannerBottom);
                setTaxOrganization(jsonData.taxOrganization);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
            }
        };

        fetchData();
    }, [code]);

    return <HomeView data={data} bannerTop={bannerTop} bannerBottom={bannerBottom} taxOrganization={taxOrganization} error={error} />;
};

export default HomeController;