import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';

const BranchesTable = ({ branches, onDelete, onRefresh }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branchName, setBranchName] = useState('');
    const [branchPhone, setBranchPhone] = useState('');
    const [branchAddress, setBranchAddress] = useState('');
    const [branchLogo, setBranchLogo] = useState(null);
    const token = sessionStorage.getItem('token');
    const [isLogoChanged, setIsLogoChanged] = useState(false);

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '8%',
        },
        {
            name: 'Branch Name',
            selector: (row) => row.branch_name,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Address',
            selector: (row) => row.branch_address,
            sortable: true,
            width: '25%',
        },
        {
            name: 'Phone',
            selector: (row) => row.branch_phone,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Logo',
            selector: (row) => row.logo ? (
                <img src={row.logo} alt="Branch Logo" style={{ width: '100px', objectFit: 'contain' }} />
            ) : 'No Logo',
            width: '15%',
        },
        {
            name: 'Actions',
            cell: (row, index) => (
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => handleEdit(row)}
                        data-toggle="modal"
                        data-target="#editBranchModal"
                        style={{ marginRight: '5px' }}
                        disabled={index === 0}
                    >
                        Edit
                    </button>
                    {/* <button
                        className="btn btn-primary"
                        style={{ marginRight: '5px' }}
                    // onClick={() => handlebranchAds(row.id_branch)}
                    >
                        Branch ads
                    </button> */}

                    <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(row.id_branch)}
                        disabled={index === 0}
                    >
                        Delete
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '25%',
        },
    ];

    const handleEdit = (branch) => {
        setSelectedBranch(branch);
        setBranchName(branch.branch_name);
        setBranchPhone(branch.branch_phone);
        setBranchAddress(branch.branch_address);
        setBranchLogo(branch.logo || null);
    };
    const handleDelete = async (branchId) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'This action will permanently delete the branch.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel',
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-branch/${branchId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Branch Deleted',
                        text: 'The branch has been deleted successfully.',
                    });
                    onRefresh();
                } else {
                    throw new Error('Failed to delete branch');
                }
            } catch (error) {
                console.error('Error deleting branch:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Delete Failed',
                    text: 'An error occurred while deleting the branch.',
                });
            }
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBranchLogo(reader.result); // Set base64 image
                setIsLogoChanged(true); // Mark logo as changed
            };
            reader.readAsDataURL(file);
            setBranchLogo(null);
            setIsLogoChanged(false); // Reset logo changed state
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedBranch(null);  // Reset selected branch
        setBranchLogo(null);      // Reset logo
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        const updatedBranchData = {
            branch_name: branchName,
            branch_phone: branchPhone,
            branch_address: branchAddress,
            logo: isLogoChanged ? branchLogo : null,
        };

        // Example API request to save changes
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/update-branch/${selectedBranch.id_branch}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedBranchData),
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Branch Updated Successfully',
                    text: 'The branch has been updated.',
                });
                onRefresh();
                const closeButton = document.querySelector('#editBranchModal .close');
                if (closeButton) {
                    closeButton.click();
                }
            } else {
                throw new Error('Failed to update branch');
            }
        } catch (error) {
            console.error('Error updating branch:', error);
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'Failed to update the branch.',
            });
        }
    };

    // Filter branches based on search term
    const filteredBranches = branches.filter(branch =>
        branch.branch_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        branch.branch_phone.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: '200px' }}
                />
            </div>

            <DataTable
                columns={columns}
                data={filteredBranches}
                striped
                responsive
                noDataComponent="No branches found."
                style={{ width: '100%' }}
                pagination
            />
            <div className="modal fade" id="editBranchModal" tabIndex="-1" role="dialog" aria-labelledby="editBranchModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editBranchModalLabel">Edit Branch</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSaveChanges}>
                                <div className="form-group">
                                    <label htmlFor="branch_name">Branch Name:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="branch_name"
                                        value={branchName}
                                        onChange={(e) => setBranchName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="branch_phone">Branch Phone:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="branch_phone"
                                        value={branchPhone}
                                        onChange={(e) => setBranchPhone(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="branch_address">Branch Address:</label>
                                    <textarea
                                        className="form-control"
                                        id="branch_address"
                                        value={branchAddress}
                                        onChange={(e) => setBranchAddress(e.target.value)}
                                        rows="1"
                                        placeholder="Enter branch address"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="logo">Logo:</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="branch_logo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                    {branchLogo && (
                                        <div style={{ marginTop: '10px' }}>
                                            <img src={branchLogo} alt="Branch Logo Preview" style={{ width: '100px', height: '100px' }} />
                                        </div>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BranchesTable;
