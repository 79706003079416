// src/views/Feedbacks.js
import React from 'react';
import AppHeaderDropdown from '../components/header/MainHeader';
import Footer from '../components/header/footer';

const Feedbacks = () => {
    return (
        <>
            <AppHeaderDropdown />
            <div className="bg-light text-center d-flex flex-column justify-content-center align-items-center vh-100">
                <h3>Coming Soon</h3>
                <p>Our website is under construction</p>
            </div>
            <Footer />
        </>
    );
};

export default Feedbacks;
